.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: blue;
    position: relative;
    animation: moveAnimation 2s infinite alternate; /* Optional: Adding animation for visual effect */
  }
  
  @keyframes moveAnimation {
    to {
      transform: translate(5px, 5px);
    }
  }
  